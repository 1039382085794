/*!* App.css *!*/
.App {
  font-family: Arial, sans-serif;
  /*text-align: center;*/
  color: #251f1f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

form {
  display: inline-block;
  text-align: left;
  padding: 20px;
  border: 2px solid #0F8570;
  border-radius: 10px;
  position: relative;
  width: 300px;
  background-color: white;
}

.file-upload {
  margin-bottom: 10px;
  padding: 5px;
}

select, button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #0F8570;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3);
  z-index: 10;
  flex-direction: column;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #0F8570;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blurred {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

label {
  display: block;
  margin-bottom: 10px;
  color: #251f1f;
  font-weight: bold;
}

button {
  background: #0f8570;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background: #0a594f;
}
